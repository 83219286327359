<template>
  <VxScroll class="rai-text-detail">
    <v-toolbar
      elevation="1"
      style="position: fixed; width: 100%; z-index: 2"
      class="rai-txt-titlebar"
    >
      <v-toolbar-title v-text="title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="overflow" />
    </v-toolbar>
    <div
      style="
        padding-top: 64px;
        padding-bottom: 64px;
        padding-left: 20px;
        padding-right: 20px;
      "
    >
      <slot />
    </div>
    <v-footer class="rai-txt-footer-form">
      <slot name="button"></slot>
      <slot name="form" />
    </v-footer>
  </VxScroll>
</template>

<script>
export default {
  name: "DetailViewDesktop",
  components: {
    VxScroll: () => import("@/components/vx/VxScroll"),
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    back: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.rai-app-sm,
.rai-app-md,
.rai-app-lg,
.rai-app-xl {
  .rai-txt-titlebar {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .rai-txt-detail::after {
    position: fixed;
    bottom: 0;
    display: block;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    margin-top: -50px;
    height: 50px;
    width: 100%;
    z-index: 1;
    content: "";
  }
  .rai-txt-footer-form {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
}
</style>
